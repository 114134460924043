import "../../styles/pages/investments.scss";

import React, { useRef } from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import ScrollTranslate from "../../components/ScrollTranslate";
import classNames from "classnames";

/**
 *
 */
function Investments({ pageContext }) {
  const investmentsData = pageContext;
  const { seo } = pageContext;
  const { title, metaDesc } = seo;

  const scrollRef = useRef(null);
  const wheelRef = useRef(null);

  const bodyData = investmentsData?.pageTemplateInvestments;
  const items = investmentsData?.pageTemplateInvestments?.investmentsItem || [];

  return (
    <Layout
      gradient={true}
      gradientMobileOnly={true}
      ref={wheelRef}
      theme="dark"
    >
      <SEO title={title} description={metaDesc} />

      <article className="Investments">
        <header className="Investments-header">
          {bodyData?.heading && (
            <h1 className="Investments-headerHeading">{bodyData?.heading}</h1>
          )}
          <p className="Investments-headerText">{bodyData?.body}</p>
        </header>
        <div className="Investments-listContainer">
          <ScrollTranslate
            className="Investments-list"
            ref={scrollRef}
            wheelRef={wheelRef}
          >
            {items.map((item, i) => (
              <div
                className={classNames("Investments-item", {
                  [`Investments-item--${item.variant}`]: item?.variant,
                })}
                key={i}
              >
                <a
                  className="Investments-itemLink"
                  href={item?.link}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    alt={item?.brandLogo?.altText}
                    className="Investments-itemImage"
                    src={item.brandLogo?.sourceUrl}
                  />
                </a>
                {item?.caption && (
                  <p className="Investments-itemCaption">{item?.caption}</p>
                )}
              </div>
            ))}
          </ScrollTranslate>
        </div>
      </article>
    </Layout>
  );
}

export default Investments;
// const investmentsData = useStaticQuery(graphql`
// query {
//   wpPage(slug: { eq: "investments" }) {
//     pageTemplateInvestments {
//       heading
//       body
//       investmentsItem {
//         brandLogo {
//           altText
//           sourceUrl
//         }
//         link
//         variant
//         caption
//       }
//     }
//   }
// }
// `);
